import React, { Component } from 'react';
import './index.css';
import { TweenMax, Expo } from 'gsap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import Bg from '../components/parallax';
import { Link } from 'gatsby';

class Home extends Component {
  componentDidMount() {
    TweenMax.to('.first', 1.5, {
      delay: 0.5,
      top: '-100%',
      ease: Expo.easeInOut,
    });

    TweenMax.to('.second', 1.5, {
      delay: 0.7,
      top: '-100%',
      ease: Expo.easeInOut,
    });

    TweenMax.to('.third', 1.5, {
      delay: 0.9,
      top: '-100%',
      ease: Expo.easeInOut,
    });
  }

  technology = [
    'html5',
    'nodejs',
    'css3',
    'js',
    'express',
    'django',
    'flask',
    'gitlab',
    'php',
    'react',
    'angularjs',
  ];

  wrapper = ['first', 'second', 'third'];

  render() {
    return (
      <>
        <Navbar />

        <div id="outer" className="main-content">
          <div className="wrapper">
            {this.wrapper.map((sequence, key) => (
              <div key={key} className={'overlay ' + sequence}></div>
            ))}
          </div>

          <Bg />

          <div className="design main-content">
            <div className="left"></div>
            <div className="right"></div>
          </div>

          <div className="header main-content">
            <div className="container _container">
              <div className="logo animated fadeInDown">
                <a href="/">
                  <img className="main-logo" src="sds.png" alt="SDS" />
                </a>
              </div>
              <h1 className="animated fadeInDown _h1">
                Software Development Section, COEP
              </h1>
              <ul className="_info animated fadeInUp">
                <li>The official club for software development of COEP Tech</li>
              </ul>
              <Link className="button animated fadeInUp" to="/about">
                Know More
              </Link>
            </div>
          </div>
        </div>

        <div className="home__footer main-content">
          <Footer />
        </div>
      </>
    );
  }
}

export default Home;
